import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"

import Layout from "../components/layout"
import Head from "../components/head"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(7, 0, 4),
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  submitButton: {
    margin: `${theme.spacing(5)}px 0`,
  },
}))

const ConsultationPagePresenter = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Head title="Free consultation" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography variant="h3" component="h1" gutterBottom align="center">
              Let me help you because it’s your important day.
            </Typography>
          </Container>
          <Container maxWidth="sm">
            <Typography
              variant="subtitle1"
              component="h2"
              gutterBottom
              align="center"
            >
              Make your event truly special with Haruka who is as passionate
              about your event's success as you are.
            </Typography>
            <Typography
              variant="subtitle2"
              component="h3"
              gutterBottom
              align="center"
            >
              Get a free consultation.
            </Typography>
            <form
              name="contact"
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <TextField
                id="name"
                name="name"
                label="Name"
                fullWidth
                margin="normal"
                required
              />
              <TextField
                id="businessName"
                name="businessName"
                label="Company/Business Name"
                fullWidth
                margin="normal"
              />
              <TextField
                id="email"
                name="email"
                label="Email"
                fullWidth
                margin="normal"
                required
              />
              <TextField
                id="phone"
                name="phone"
                label="Phone"
                fullWidth
                margin="normal"
                required
              />
              <TextField
                id="event-location"
                name="event-location"
                label="Event Location"
                fullWidth
                required
                margin="normal"
              />
              <TextField
                id="number-of-guests"
                name="number-of-guests"
                label="Approximate Number of Guests"
                fullWidth
                margin="normal"
              />
              <TextField
                id="event-date"
                name="event-date"
                label="Event Date (or best guess)"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                margin="normal"
              />
              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">
                  In which language would you prefer for my service?
                </FormLabel>
                <RadioGroup aria-label="language">
                  <FormControlLabel
                    name="language"
                    value="Japanese and English"
                    control={<Radio />}
                    label="Japanese and English"
                  />
                  <FormControlLabel
                    name="language"
                    value="English only"
                    control={<Radio />}
                    label="English only"
                  />
                  <FormControlLabel
                    name="language"
                    value="Japanese only"
                    control={<Radio />}
                    label="Japanese only"
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                id="comments"
                name="comments"
                label="Please feel free to share any additional Information and comments including your budget."
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.submitButton}
              >
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Grid>
            </form>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

const ConsultationPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          companyName
          email
          description
          tel
        }
      }
    }
  `)

  return <ConsultationPagePresenter data={data} />
}

export default ConsultationPage
